@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css?family=IBM+Plex+Sans+Thai+Looped");

* {
  font-family: "IBM Plex Sans Thai Looped", cursive;
}

.heading--main {
  color: #58595b;
  font-weight: 700;
  font-size: 1.5rem;
}

.button-percent_plan {
  padding: 4px;
  border-radius: 12px;
  background-color: #ffeed3;
  cursor: pointer;
}
.primary-color {
  color: #fcb854;
}

.main-color {
  color: #58595b;
}

.mywallet-card {
  padding: 5px;
  min-height: 25vh;
  height: max-content;
  background-color: #ffffff;
  /* background: linear-gradient(145.17deg, #FFDB5E 17.17%, #FAAE50 88.61%); */
  border-radius: 16px;
  color: #b58c3e;
}

.mywallet-card__content {
  width: 100%;
  padding: 0.5rem;
}

.mywallet-card__dtgo-logo {
  height: 3.2rem;
  margin-right: 0.3rem;
}

.vl {
  border-left: 1.5px solid #b58c3e;
  margin-left: 1.3rem;
  margin-right: 1rem;
  height: 7vh;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 columns */
  grid-gap: 12px; /* Gap between items */
  padding: 8px;
}

.grid-item {
  background-color: #fff;
  border-radius: 12px;
  justify-self: start;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* เพิ่ม box-shadow */
  transition: box-shadow 0.3s ease;
  padding: 8px;
}

.grid-item img {
  width: 100%;
  padding: 4px;
  height: auto;
  border-radius: 22px;
}

.grid-item span {
  display: block;
  width: 100%;
  font-weight: bold;
  justify-content: end;
  margin: 0.5rem 0;
}

.grid-item button {
  background-color: #ffbb3b;
  border: none;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 28px;
  padding-right: 28px;
  border-radius: 8px;
  cursor: pointer;
  border-radius: 22px;
}

.grid-item button:hover {
  background-color: #ffbb3b;
}
.card__line {
  height: 0.5px;
  background: white;
  border: none;
  color: white;
  /* old IE */
  background-color: white;
  opacity: 1;
  margin: 0.5rem 0;
}
.congrat-line {
  height: 0.5px;
  background: #ffbb3b;
  border: none;
  color: #ffbb3b;
  /* old IE */
  background-color: #ffbb3b;
  opacity: 1;
  margin: 0.5rem 0;
}
.redeem__line {
  height: 0.5px; /* Set height to 0 */
  border: none;
  border-top: 2px dashed #ffbb3b; /* Create the dashed line using border-top */
  color: white;
  background-color: transparent;
  margin: 0.5rem 0; /* Ensure the background is transparent */
  opacity: 1;
}

.shadow-card {
  box-shadow: -20px 0 2px -20.23px #ffc139, 0 -2px 21.23px #ffc139;
  background: -webkit-linear-gradient(left, #ffc139, #ffb100);
}
.document-card {
  background: -webkit-linear-gradient(left, #ffc139, #ffb100);
}

.mywallet-card__infinyx-logo {
  height: 1.5rem;
}

.info-dtgo-logo {
  height: 3.5rem;
}

.mywallet-card__normal-text {
  font-size: 1.05rem;
  line-height: 1.25;
}

.mywallet-card__name-text {
  font-size: 0.85rem;
  line-height: 1.25;
}

.mywallet-card__email-text {
  font-size: 0.85rem;
  color: #6b5325;
  line-height: 1.25;
}

.mywallet__card_plan {
  background: #f9f9fb;
  border: 1px solid #eeeeef;
  border-radius: 16px;
}

.mywallet-card__large-text {
  background: -webkit-linear-gradient(left, #ffce7a, #ffae00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-seq {
  -webkit-text-security: disc;
}

.yellow-gradient {
  background: -webkit-linear-gradient(left, #ffce7a, #ffae00);
}

.missioncard-gradient {
  background: -webkit-linear-gradient(left, #ffeac0, #ffda92, #ffc65c);
}

.card-gradient {
  background: -webkit-linear-gradient(left, #ffc139, #ffb100);
}

.mywallet-card-text {
  background: -webkit-linear-gradient(left, #ffce7a, #ffae00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 2rem;
}

.mywallet-card__line {
  height: 1.5px;
  background: #ded7c8;
  border: none;
  color: #ded7c8; /* old IE */
  background-color: #ded7c8;
  opacity: 1;
  margin: 0.5rem 0;
}

.mywallet__detail {
  display: inline-flex;
  width: 100%;
  font-size: 0.8rem;
}

.mywallet__balance.child {
  display: inline-block;
  align-self: flex-end;
}

.mywallet__icon {
  margin-left: 10px;
  margin-right: 10px;
}

.mywallet-card__topup-text {
  font-size: 1rem;
  color: #8c6c30;
  line-height: 1.25;
  font-weight: 600;
  margin: 4px 0;
}

.view-mission_button {
  border-radius: 16px;
  gap: 2px;
  cursor: pointer;
}
.done-mission_button {
  padding: 8px;
  border-radius: 12px;
  gap: 2px;
  cursor: pointer;
}

.opacity-grey {
  filter: opacity(0.5) drop-shadow(0 0 0 #f1f1f1);
}

.mywallet-card__topup-button {
  padding: 4px;
  border-radius: 12px;
  border: 1px solid #e8b34f;
  background-color: #fdfdfd;
  cursor: pointer;
}

.mywallet-card__bonus {
  padding: 8px;
  border-radius: 24px;
  background-color: #fbeed5;
  cursor: pointer;
}

.mywallet-card__grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}
.mywallet-card__grid-left {
  grid-area: 1 / 1 / 4 / 3;
}
.mywallet-card__grid-right {
  grid-area: 1 / 4 / 2 / 5;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.pay-card {
  padding: 10px;
  min-height: 16vh;
  height: max-content;
  background: linear-gradient(145.17deg, #ffdb5e 17.17%, #faae50 88.61%);
  border-radius: 16px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  color: white;
}

.pay-heading {
  justify-content: center;
  padding: 10px;
  width: 100%;
  background: #ffc557;
  color: white;
}

.pay-card__large-text {
  font-size: 1.5rem;
}

.qr-card {
  color: #ffc557;
  border: 1px solid;
  min-height: 5vh;
  border-style: solid;
}
.redeem-qr {
  height: 30vh;
}

.document__detail {
  display: inline-flex;
  width: 100%;
  font-size: 1rem;
  float: left;

  border-radius: 12px;
  min-height: 5vh;
  border-style: solid;
  padding: 1rem 1rem 1rem 0rem;
}

.pay-qr {
  height: 35vh;
}
.topup-qr {
  height: 14.5rem;
}

.info-view-btn {
  align-items: center;
  padding: 4px 12px;
  height: 26px;
  /* background: #FFF9CB;
    border-radius: 16px;
    color: #F08944; */
  color: #e8b34f;
  background: #fff9ee;
  border: 1px solid #e8b34f;
  border-radius: 16px;
  border: 0;
  float: right;
  margin-top: 0.8rem;
}

.info__detail {
  display: inline-flex;
  width: 100%;
  font-size: 1rem;
  float: left;

  border-radius: 12px;
  border: 1px solid;
  min-height: 5vh;
  border-style: solid;
  border-color: #f08944;
  padding: 1rem 1rem 1rem 0rem;
}

.info__logo {
  margin-right: 1rem;
  margin-left: 1rem;
}

.info__detail-content {
  width: inherit;
}

.info__detail-content .child {
  padding-left: 8px;
  float: left;
}

.history__heading {
  color: #58595b;
}

.history__detail {
  display: inline-flex;
  width: 100%;
  font-size: 1rem;
}

.history__amount {
  color: #58595b;
}

.history__timestamp {
  color: #9c9c9c;
}

.deeplink-menu {
  overflow: hidden;
  position: fixed;
  bottom: 6rem;
  height: 3rem;
  width: 100%;
  background-color: red;
}

/* Place the navbar at the bottom of the page, and make it stick */
.navbar {
  background-color: #f9f9fb;
  overflow: hidden;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 6rem;
  padding: 0.5rem;
}

.navbar-mission {
  overflow: hidden;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 4.5rem;
  padding: 0.5rem;
  text-align: center;
}

/* Style the links inside the navigation bar */
.navbar a {
  float: left;
  display: block;
  color: #58595b;
  text-align: center;
  padding: 0.5rem 0.5rem;
  text-decoration: none;
  font-size: 1rem;
  width: calc(100% / 4);
}

/* Change the color of links on hover */
/* .navbar a:hover {
    background-color: #ddd;
    color: black;
  } */

/* Add a color to the active/current link */

/* background-color: #FFC557; */

.navbar a.active {
  background: -webkit-linear-gradient(left, #ffce7a, #ffae00);
  color: white;
  text-align: center;
  border-radius: 12px;
}

.refresh-button {
  padding: 8px;
  border: 1px solid #b58c3e;
  border-radius: 12px;
  color: #b58c3e;
  background-color: white;
}

.button-topup {
  padding: 3px;
  border-radius: 12px;
  color: #b58c3e;
  background-color: #fbeed5;
}
/* TOPUP */
.topup_amount-box {
  margin: 16px;
  height: 90vh;
  position: relative;
}
.topup_amount-header {
  color: #3e3f41;
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: -0.6000000238418579px;
  text-align: left;
}

.topup_amount-input-container {
  display: flex;
  border-radius: 0.25rem;
  align-items: center; /* This will ensure that children are vertically centered */
  border: 1px solid #ccc;

  position: relative;
}

.topup-refresh-button {
  padding: 16px;
  border: 1px solid #b58c3e;
  border-radius: 12px;
  color: #b58c3e;
  background-color: white;
}
.topup-cancel-button {
  padding: 16px;
  border: 1px solid #58595b;
  border-radius: 12px;
  color: #58595b;
  background-color: white;
}
.topup-loader-overlay {
  background-color: #111;
  opacity: 0.8;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 999;
}
.topup-loader-modal {
  display: flex;
  width: 5.1875rem;
  height: 5.25rem;
  padding: 1.125rem 0.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  opacity: 0.9;
  background-color: #000;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
  z-index: 1000;
}

.symbol {
  margin: 0.5rem 0.9rem;
}

.divider {
  height: 100%;
  min-height: 2.5rem;
  width: 1px;
  background-color: #ebebeb;
  margin-right: 0.5rem;
}

.number-input {
  flex-grow: 1;
  border: none;
  outline: none;
}

.cross-icon {
  cursor: pointer;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.topup_amount-text {
  color: #e8b34f;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.6px;
}
.topup_amount-button-container {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}
.topup_amount-button {
  display: flex;
  padding: 12px 12px 12px 6px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid var(--grey-grey-200, #b2b3b4);
}

.topup_next-button {
  display: block;
  border: 1px solid #e8b34f;
  background: linear-gradient(0deg, #ffc557, #ffc557),
    linear-gradient(0deg, #e8b34f, #e8b34f);
  width: 100%;
  height: 3em;
  border-radius: 12px;
  position: absolute;
  bottom: 3rem;
  left: 50%;
  transform: translateX(-50%); /* To center the button horizontally */
}
.topup_next-button:disabled {
  border: 1px solid #ebebeb;
  background: var(--neutral-neu-300, #ebebeb);
}

.topup_valid-icon {
  color: #e8b34f;
  height: 16px;
  vertical-align: middle;
  display: inline-block;
  margin-top: -2px;
  margin-right: 4px;
}

.topup_invalid-text {
  color: #e46666;
}

.react-tabs {
  -webkit-tap-highlight-color: transparent;
  width: 100%;
}

.react-tabs__tab-list {
  margin: 0 0 10px;
  padding: 0;
  width: 100%;
}

.react-tabs__tab {
  display: inline-block;
  cursor: pointer;
  text-align: center;
  font-weight: 700;
  width: calc(100% / 2);
  padding: 15px;
  border-bottom: 2.5px solid #aaa;
  color: grey;
}

.react-tabs__tab--selected {
  border-bottom: 2.5px solid #b5a05e;
  color: #b5a05e;
}
